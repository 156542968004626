import { SearchInput } from "@/react/components/Modals/MessagingModal/LeftSidebar/List/SearchInput";

export interface CommunityInboxChatSearchProps {
  searchQuery: string;
  setSearchQuery: (value: string) => void;
  setIsSearchBarVisible: (value: boolean) => void;
}

export const CommunityInboxChatSearch = ({
  searchQuery,
  setSearchQuery,
  setIsSearchBarVisible,
}: CommunityInboxChatSearchProps) => {
  const handleOnChange = (value: string) => {
    setSearchQuery(value);
  };

  const onClickCancel = () => {
    setSearchQuery("");
    setIsSearchBarVisible(false);
  };

  const handleOnEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (!event.shiftKey && event.key === "Enter") {
      event.preventDefault();
    }
  };

  return (
    <div className="border-primary -mx-2 w-full items-center p-2">
      <SearchInput
        searchValue={searchQuery}
        onChange={handleOnChange}
        onKeyPress={handleOnEnter}
        onClickCancel={onClickCancel}
        showCancelButton
      />
    </div>
  );
};
