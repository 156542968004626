import { t } from "@/i18n-js/instance";
import { useSpacesForFilters } from "@/react/components/Spaces/hooks/useSpacesForFilters";
import { useFilterContext } from "@circle-react/components/Modals/SearchResultsModal/FilterBar/FilterContext";
import { useSpacesContext } from "@circle-react/contexts";
import { isChatSpace } from "@circle-react/helpers/spaceHelpers";
import {
  CheckboxFilterV2 as CheckboxFilter,
  Filter,
} from "@circle-react-shared/Filter";
import { Loader } from "@circle-react-uikit/Loader";

const localeScope = "search_v2.filters";

export const ChatSpaceFilter = () => {
  const { filters, setFilters } = useFilterContext();
  const { helpers } = useSpacesContext();

  const filterSpaces = (space: any) =>
    isChatSpace(space) && space?.is_chat_participant;

  const spaces = useSpacesForFilters({
    spaces: helpers.spaceWithViewContentAccess().filter(filterSpaces),
    valueKey: "chat_room_id",
  });

  return (
    <Filter
      chip={t([localeScope, "chat"])}
      title={t([localeScope, "chat"])}
      selectedText={
        filters?.chat_room_ids
          ? t([localeScope, `n_chats`], {
              count: filters?.chat_room_ids?.length || 0,
            })
          : ""
      }
      onApply={data => {
        setFilters({
          ...filters,
          chat_room_ids: (data?.value || []).length ? data?.value : null,
        });
      }}
      shouldShowRemoveButton={!!filters?.chat_room_ids}
    >
      {helpers.isDataLoading() ? (
        <Loader />
      ) : (
        <CheckboxFilter
          options={spaces}
          className="w-full"
          param={{
            id: "chat_room_ids",
            key: "chat_room_ids",
            value: filters?.chat_room_ids || [],
          }}
          legend={t([localeScope, "chats"])}
          hasSearch
          searchProps={{
            placeholder: t([localeScope, `search_chats`]),
          }}
        />
      )}
    </Filter>
  );
};
