import { useEffect, useState } from "react";
import { t } from "@/i18n-js/instance";
import { IconButton } from "@/react/components/shared/uikit/HeaderV3/IconButton";
import { usePunditUserContext } from "@/react/contexts/punditUserContext";
import type {
  Agent,
  ChatSortType,
  ChatType,
} from "@/react/types/CommunityBot/ChatSort";
import { useIndexAgents } from "@circle-react/components/SettingsApp/CommunityBot/agentsQueries";
import { Icon } from "@circle-react-shared/Icon";
import { TippyV2 } from "@circle-react-shared/TippyV2";
import { Dropdown } from "@circle-react-shared/uikit/Dropdown";
import { AgentDropdown } from "./AgentDropdown";
import { ChatTypesDropdown } from "./ChatTypesDropdown";
import { DropdownMenu } from "./DropdownMenu";
import { ALL_AGENTS_INITIAL_STATE, CHAT_SORT_VALUES } from "./constants";

interface HeaderProps {
  activeSort: ChatSortType;
  setActiveSort: (value: ChatSortType) => void;
  activeChatType: ChatType;
  setActiveChatType: (value: ChatType) => void;
  activeAgent: Agent;
  setActiveAgent: (value: Agent) => void;
  setIsSearchBarVisible: (value: boolean) => void;
}
export const Header = ({
  activeSort,
  setActiveSort,
  activeChatType,
  setActiveChatType,
  activeAgent,
  setActiveAgent,
  setIsSearchBarVisible,
}: HeaderProps) => {
  const { currentCommunitySettings } = usePunditUserContext();
  const {
    community_inbox_chat_search_enabled: isCommunityInboxChatSearchEnabled,
  } = currentCommunitySettings || {};
  const [agents, setAgents] = useState<Agent[]>([ALL_AGENTS_INITIAL_STATE]);
  const { data: agentResults } = useIndexAgents({ currentPage: 1 });

  useEffect(() => {
    if (agentResults?.records && agentResults.records.length > 0) {
      setAgents([ALL_AGENTS_INITIAL_STATE, ...agentResults.records]);
    }
  }, [agentResults]);

  return (
    <div className="bg-primary sticky top-0 z-10 my-1 flex h-10 items-center justify-between gap-2 px-2">
      <div className="flex items-center gap-2">
        <ChatTypesDropdown
          className="min-w-fit"
          activeItem={activeChatType}
          localeScope="community_inbox.filters"
          onItemClick={setActiveChatType}
        />
        <AgentDropdown
          className="min-w-fit"
          activeItem={activeAgent}
          onItemClick={value => setActiveAgent(value)}
          items={agents}
        />
      </div>
      <div className="flex items-center gap-2">
        <DropdownMenu
          className="min-w-fit"
          items={Object.values(CHAT_SORT_VALUES)}
          activeItem={activeSort}
          localeScope="community_inbox.filters"
          onItemClick={setActiveSort}
          button={
            <Dropdown.MenuButton className="hover:bg-overlay-dark flex justify-between rounded-full bg-transparent !py-1.5 px-1 transition-colors">
              <Icon type="16-sort-v2" className="text-default" size={16} />
            </Dropdown.MenuButton>
          }
          direction="bottom-end"
        />
        {isCommunityInboxChatSearchEnabled && (
          <TippyV2
            interactive={false}
            content={t("community_inbox.header.search_messages")}
          >
            <IconButton
              iconSize={20}
              buttonSize={24}
              name="20-search-v3"
              ariaLabel={t("community_inbox.header.search_messages")}
              className="!rounded-md"
              onClick={() => setIsSearchBarVisible(true)}
            />
          </TippyV2>
        )}
      </div>
    </div>
  );
};
